/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./Hot.css";

import second from "../../assets/game/hot/low/meat.png";
import third from "../../assets/game/hot/low/mushroom.png";
import fourth from "../../assets/game/hot/low/peppers.png";
import fifth from "../../assets/game/hot/low/onions.png";
import sixth from "../../assets/game/hot/low/jalapenos.png";
import seventh from "../../assets/game/hot/low/cheese.png";
import eighth from "../../assets/game/hot/low/breadtop.png";

import Sandwich from "../Sandwich/Sandwich";

// import eighth from "../../assets/game/hot/low/bread top.png";

import Draggable from "react-draggable";

import duck from "../../assets/duck.png";
import football from "../../assets/football.png";
import bear from "../../assets/bear.png";

import catchbun from '../../assets/texts/CatchBun.png'

const Hot = ({ setPoints, setWPoints, playCustomAudio }) => {
  const [x, setX] = useState(window.innerWidth / 3.7);
  const [y, setY] = useState(window.innerHeight * 0.8);
  // const [fullWidth, setFullWidth] = useState();
  const [yPos, setYPos] = useState(0);
  const [testPoints, setTestPoints] = useState([]);
  const [wrongPoints, setWrongPoints] = useState([]);

  const [plusTen, setPlusTen] = useState(false);

  if (yPos < 1000) {
    setTimeout(() => {
      setYPos(yPos + 0.3);
    }, 7);
  }

  const [ingredients, setIngredients] = useState([
    [second, 30, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [third, 28, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [fourth, 24, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [fifth, 20, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [sixth, 18, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [seventh, 16, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [second, 30, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [third, 28, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [fourth, 24, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [fifth, 20, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [sixth, 18, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [seventh, 16, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [second, 30, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [third, 28, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [fourth, 24, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [fifth, 20, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [sixth, 18, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [seventh, 16, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [second, 30, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [third, 28, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [eighth, 14, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],

  ]);

  const [wrongIngr, setWrongIngr] = useState([
    [duck, 20, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"], 
    [football, -20, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [duck, -60, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -105, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [duck, -140, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -180, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [football, -220, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [duck, -260, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [football, -290, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -330, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [duck, -370, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [football, -410, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -450, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],

    [duck, -490, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"], 
    [football, -530, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [duck, -570, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -610, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [duck, -650, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -690, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [football, -720, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],   
    [bear, -770, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],

    [duck, -800, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"], 
    [football, -820, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
    [bear, -840, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],

    [duck, -860, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"], 
    [football, -880, Math.floor(Math.random() * (60 - 9 + 1) + 9) + "%"],
  ]);


  const wrongArray = [ 
    'duck',
    'football',
    'duck',
    'bear',
    'duck',
    'bear',
    'football',
    'duck',
    'football',
    'bear',
    'duck',
    'football',
    'bear',

    'duck',
    'football',
    'duck',
    'bear',
    'duck',
    'bear',
    'football',
    'bear',
    'duck',
    'football',
    'bear',
    'duck',
    'football',
  ]

  const ingrPercentages = [];

  for (let i = 0; i < ingredients.length; i++) {
    ingrPercentages.push(((yPos - i * 40) * 100) / 100);
  }

  useEffect(() => {
    setPoints(testPoints);
    setWPoints(wrongPoints);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testPoints, wrongPoints]);

  const negXCalc = (x) => {
    // let percentage = 0;

    // for(let i=-113; i<=x; i++){
    //   percentage += 0.45045045045045046;
    // }
    // return(percentage);
    const total = (57 / 100) * window.innerWidth;
    return (x * 100) / total;
  };
  wrongIngr.forEach((ingr, index) => {
    let item = ((yPos - index + 1 * ingr[1]) * 100) / 100;
    if (
      (100 * y) / ((88 / 100) * window.innerHeight) - item < 0.25 &&
      (100 * y) / ((88 / 100) * window.innerHeight) - item > -1
    ) {
      // console.log((100 * y) / (88 / 100 * window.innerHeight) - item, (100 * y) / (88 / 100 * window.innerHeight) - item)
      if (
        Math.abs(
          negXCalc(x) - (+wrongIngr[index][2].split("%")[0] / 69) * 100
        ) < 20
      ) {
        if (!wrongPoints.includes(index)) {
          setWrongPoints((wrongPoints) => [...wrongPoints, index]);

          if (wrongArray[index] === "duck") {
            setPlusTen("-5");
          } else if (wrongArray[index] === "football") {
            setPlusTen("-15");
          } else if (wrongArray[index] === "bear") {
            setPlusTen("-10");
          }

          playCustomAudio("audio5");

          setTimeout(() => {
            setPlusTen(false);
          }, 350);
        }
      }
    }
  });
  ingrPercentages.forEach((item, index) => {
    // if ((100 * y) / 748 > item && ((100 * y - 45) / 748)  < item) {
    if (
      (100 * y) / ((88 / 100) * window.innerHeight) - item < 1 &&
      (100 * y) / ((88 / 100) * window.innerHeight) - item > -16
    ) {
      if (
        negXCalc(x) - (+ingredients[index][2].split("%")[0] / 69) * 100
         < 50 &&
         negXCalc(x) - (+ingredients[index][2].split("%")[0] / 69) * 100
         > -30
      ) {
        if (!testPoints.includes(index)) {
          setTestPoints((testPoints) => [...testPoints, index]);
          playCustomAudio("audio4");

          if (index !== 20) {
            setPlusTen("+10");
            setTimeout(() => {
              setPlusTen(false);
            }, 350);
          }
        }
      }
    }
  });

  return (
    <div className="cold-container">
      <div className="ingredients-parent">
        {wrongIngr.map((item, index) => {
          if (
            yPos - index + 1 * item[1] > 5 &&
            yPos - index + 1 * item[1] < 5.1
          ) {
            if (
              item[0].toString() ===
              "/static/media/football.89e8fbb02fa65dc81ed0.png"
            ) {
              playCustomAudio("audio7");
            } else if (
              item[0].toString() ===
              "/static/media/bear.4fe4fcf306343c1dcd84.png"
            ) {
              playCustomAudio("audio6");
            } else if (
              item[0].toString() ===
              "/static/media/duck.f7e524c13e82408dd5f7.png"
            ) {
              playCustomAudio("audio8");
            }
          }
          return (
            <img
              src={item[0]}
              alt="ingredient"
              className={`ingredient wrong-ingr ${
                wrongPoints.includes(index) ? " catched" : ""
              }`}
              style={{
                top: yPos - index + 1 * item[1] + "vh",
                left: item[2],
              }}
            />
          );
        })}
        {ingredients.map((ingredient, index) => {
          return (
            <img
              className={`ingredient ${
                testPoints.includes(index) ? " catched" : ""
              }`}
              style={{
                left: ingredient[2],
                top: yPos - index * 40 + "vh",
                display:
                  yPos - index * 40 <= 130
                    ? "block"
                    : yPos - index * 40 <= 0
                    ? "block"
                    : "none",
              }}
              src={ingredient[0]}
              alt="ingredient"
            />
          );
        })}
      </div>
      {testPoints.includes(6) ? null : yPos > 800 && yPos < 900 ? (
        <div className="catchthebun">
          <img style={{width: '50vw'}} src={catchbun} alt="catchbun" />
          {/* <h2>CATCH THE BUN!</h2> */}
        </div>
      ) : null}

      <div style={{ height: "100vh", padding: "13vh 0% 10vh 0%" }}>
        <Draggable
          bounds="parent"
          handle=".handle"
          onDrag={(e, ui) => {
            setY(ui.y);
            setX(ui.x);
          }}
          defaultPosition={{ x: x, y: y }}
        >
          <span className="handle sandwich-container">
            {plusTen && (
              <small
                style={{ color: +plusTen < 0  && "red"  }}
                className="plus10"
              >
                {plusTen}
              </small>
            )}
            <Sandwich type={"hot"} points={testPoints} />
          </span>
        </Draggable>
      </div>
    </div>
  );
};

export default Hot;
