/* eslint-disable jsx-a11y/alt-text */
import React from 'react'

import first from "../../assets/game/cold/low/1.png";
import second from "../../assets/game/cold/low/2.png";
import third from "../../assets/game/cold/low/3.png";
import fourth from "../../assets/game/cold/low/4.png";
import fifth from "../../assets/game/cold/low/5.png";
import sixth from "../../assets/game/cold/low/6.png";
import seventh from "../../assets/game/cold/low/7.png";
import eighth from "../../assets/game/cold/low/9.png";
import ninth from "../../assets/game/cold/low/10.png";
import tenth from "../../assets/game/cold/low/8.png";
import eleventh from "../../assets/game/cold/low/12.png";
import twelve  from "../../assets/game/cold/low/13.png"
import thirteenth from "../../assets/game/cold/low/11.png"

import secondC from "../../assets/game/hot/low/meat.png";
import thirdC from "../../assets/game/hot/low/mushroom.png";
import fourthC from "../../assets/game/hot/low/peppers.png";
import fifthC from "../../assets/game/hot/low/onions.png";
import sixthC from "../../assets/game/hot/low/jalapenos.png";
import seventhC from "../../assets/game/hot/low/cheese.png";
import eighthC from "../../assets/game/hot/low/breadtop.png";

import './Sandwich.css'

const Sandwich = ({type, points}) => {
    if(type === 'cold'){
        points = points.filter(point => point !== 9).filter(point => point !== 10)
    }
    // points=[0, 1, 2, 3, 4, 5, 6, 7, 8, 11, 12, 13, 14,15,16,17,18,19,22]

    const ingrArray = [
        [second, 20],
        [third, 50],
        [fourth, 55],
        [fifth, 65],
        [sixth, 75],
        [seventh, 85],
        [eighth, 90],
        [ninth, 95],
        [tenth, 100],
        [eleventh, 105],
        [twelve, 108],
 
        [second,110],
        [third, 112],
        [fourth, 115],
        [fifth, 120],
        [sixth, 130],
        [seventh, 135],
        [eighth, 140],
        [ninth, 145],
        [tenth, 150],

        [thirteenth, 165],
    ]

    const ingrArrayC = [
        [secondC, 50],
        [thirdC, 55],
        [fourthC, 60],
        [fifthC, 70],
        [sixthC, 75],
        [seventhC, 85], 

        [secondC, 90],
        [thirdC, 95],
        [fourthC, 100],
        [fifthC, 108],
        [sixthC, 112],
        [seventhC, 115],

        [secondC, 118],
        [thirdC, 120],
        [fourthC, 125],
        [fifthC, 130],
        [sixthC, 135],
        [seventhC, 140],

        [secondC, 145],
        [thirdC, 150],
        
        [eighthC, 155],
    ]


  return (
        <>
            {
                type === 'cold' ?
                
                <span className='cold-sand-container'>
                    {
                        points.reverse().map((point, index) => {
                            return (
                                <img key={index} style={{position: 'absolute', bottom: ingrArray[point][1] + '%', zIndex: ingrArray[point][1]}} src={ingrArray[point][0]} alt=''/>
                            )
                        })
                    }
                    <img src={first} alt=""/>

                </span>
                :
                <span className='hot-sand-container'>
                                        {
                        points.reverse().map((point, index) => {
                            return (
                                <img key={index} style={{position: 'absolute', bottom: ingrArrayC[point][1] + '%', zIndex: ingrArrayC[point][1]}} src={ingrArrayC[point][0]} alt=''/>
                            )
                        })
                    }
                    <img src={first} alt=""/>
                </span>

            }
      </>


    
  )
}

export default Sandwich