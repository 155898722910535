/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import bronzeMedal from "../../assets/congrat/bronze.png";
import silverMedal from "../../assets/congrat/silver.png";
import goldMedal from "../../assets/congrat/gold.png";
import cameraLogo from "../../assets/congrat/camera.png";
import finalFrame from "../../assets/final-frame.png";
// import goldM from "../../assets/goldMedal.png";
// import silverM from "../../assets/silverMedal.png";
// import bronzeM from "../../assets/bronzeMedal.png";
import playAgain from "../../assets/playagain.png";
// import * as tf from "@tensorflow/tfjs";
// import * as posenet from "@tensorflow-models/posenet";
import Webcam from "react-webcam";
// import { drawKeypoints, drawSkeleton, renderImageToCanvas } from "./utilities";
import "./MedalPosing.css";
import html2canvas from "html2canvas";
import close from "../../assets/close.png";
import ReactGA from "react-ga4";

const MedalPosing = ({ points, medal, restartGame }) => {
  const [imageURL, setImageURL] = useState(null);
  const [flash, setFlash] = useState(false);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  // const [xy, setXY] = useState({ x: 0, y: 0 });
  // const [shoulderDistance, setShoulderDistance] = useState(0);

  const [captured, setCaptured] = useState(false);
  // Load Posenet
  // const runPosenet = async () => {
  //   const net = await posenet.load();

  //   setInterval(() => {
  //     detect(net);
  //   }, 100);
  // };

  // useEffect(() => {
  //   runPosenet();
  // }, []);

  // const detect = async (net) => {
  //   if (
  //     typeof webcamRef.current !== "undefined" &&
  //     webcamRef.current !== null &&
  //     webcamRef.current.video.readyState === 4
  //   ) {
  //     const video = webcamRef.current.video;
  //     const videoWidth = video.videoWidth;
  //     const videoHeight = video.videoHeight;

  //     webcamRef.current.video.width = videoWidth;
  //     webcamRef.current.video.height = videoHeight;

  //     const pose = await net.estimateSinglePose(video);
  //     drawCanvas(pose, video, videoWidth, videoHeight, canvasRef);
  //   }
  // };

  // const drawCanvas = (pose, video, videoWidth, videoHeight, canvas) => {
  //   const ctx = canvas.current.getContext("2d");
  //   canvas.current.width = videoWidth;
  //   canvas.current.height = videoHeight;
  //   // drawKeypoints(pose["keypoints"], 0.5, ctx);
  //   // drawSkeleton(pose["keypoints"], 0.5, ctx);
  //   console.log((pose['keypoints'][5].position.x - pose['keypoints'][6].position.x))
  //   ctx.drawImage(
  //     document.getElementById("testingForCanvas"),
  //     pose["keypoints"][6].position.x -
  //       (pose["keypoints"][5].position.x - pose["keypoints"][6].position.x) *
  //       0.0004 *
  //         (pose["keypoints"][6].position.x - pose["keypoints"][5].position.x),
  //     (pose["keypoints"][5].position.y + pose["keypoints"][6].position.y) / 2 - 50,
  //     (pose['keypoints'][5].position.x - pose['keypoints'][6].position.x) * 0.65,
  //     (pose['keypoints'][5].position.x - pose['keypoints'][6].position.x) * 0.75
  //   );

  // };

  const camLogoClick = () => {
    setCaptured("show");
    setFlash(true);

    const elem = document.getElementsByTagName("body")[0];

    if (elem) {
      setTimeout(() => {
        html2canvas(elem, {
          useCORS: true,
        }).then((canvas) => {
          setImageURL(canvas.toDataURL());
          setCaptured(false);
        });
      }, 1010);
    }
  };

  if (captured) {
    setTimeout(() => {
      setFlash(false);
    }, 1000);
  }

  return (
    <>
      {" "}
      {imageURL && (
        <div
          style={{
            zIndex: "1000",
            position: "absolute",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#000000cc",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "12vh",
              left: "50vw",
              pointerEvents: "all",
            }}
          >
            <img
              alt="alt"
              style={{
                width: "75vw",
                height: "70vh",
                marginLeft: "-50%",
                border: "2px solid white",
                borderRadius: "0px",
              }}
              src={imageURL}
              onClick={async () => {
                if (navigator.canShare) {
                  try {
                    ReactGA.event({
                        category: "picture_page_clicks",
                        action: `${'download'}`
                    });
                }catch(e){ console.log("ERR",e);}
    
                  const response = await fetch(imageURL);
                  const blob = await response.blob();
                  const filesArray = [
                    new File([blob], `selfie-${Date.now()}.jpg`, {
                      type: "image/jpeg",
                      lastModified: new Date().getTime(),
                    }),
                  ];
                  const shareData = {
                    files: filesArray,
                  };
                  navigator.share(shareData);
                } else {
                  const a = document.createElement("a");
                  a.href = imageURL;
                  a.download = `car${Date.now()}.jpg`;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                }
              }}
            ></img>{" "}
          </div>{" "}
          <div
            className='taptodownload-container'
          >
            <h1
            >
              tap to download{" "}
              <img
              onClick={() => 
                
                {
                  if (navigator.canShare) {
                    try {
                      ReactGA.event({
                          category: "picture_page_clicks",
                          action: `${'play_again'}`
                      });
                  }catch(e){ console.log("ERR",e);}
                }
                  
                  restartGame()
                }
              }

              alt="alt"
              src={playAgain}
            />{" "}
            </h1>
            
          </div>{" "}
          <div
            style={{
              position: "absolute",
              right: "17vw",
              top: "13vh",
            }}
          >
            <img
              alt="alt"
              onClick={() => {
                if (navigator.canShare) {
                  try {
                    ReactGA.event({
                        category: "picture_page_clicks",
                        action: `${'close'}`
                    });
                }catch(e){ console.log("ERR",e);}
              }
                setImageURL(null);
              }}
              style={{
                pointerEvents: "all",
                width: "30px",
                height: "30px",
              }}
              src={close}
            />{" "}
          </div>{" "}
        </div>
      )}{" "}
      {flash && <div className={`flash  is-flashing`}>. </div>}{" "}
      <div className="center-some-testing">
        <div className="webcam-container">
          <Webcam ref={webcamRef} style={{}} />{" "}
          {/* <canvas id="canvas" ref={canvasRef} style={{}} />{" "} */}{" "}
        </div>{" "}
      </div>{" "}
      <div className={`congrat-container relative `}>
        {" "}
        {/* <div className="best-score">
                <h4> BEST SCORE: </h4> <h5> {points * 10} </h5>{" "}
              </div>{" "} */}{" "}
        <div
          style={{
            display: captured === "show" ? "block" : "none",
          }}
          className="medal-container"
        >
          <h2> {points} </h2> <img src={finalFrame} alt="alt" />
        </div>{" "}
        <div className="cam-logo">
          <img
            style={{
              display: captured ? "none" : "block",
            }}
            onClick={() => {
              if (navigator.canShare) {
                try {
                  ReactGA.event({
                      category: "picture_page_clicks",
                      action: `${'take_picture'}`
                  });
              }catch(e){ console.log("ERR",e);}
            }
              camLogoClick();
            }}
            src={cameraLogo}
            alt="cameraLogo"
            className="cameraLogo"
          />
        </div>{" "}
        {/* <div>
                <img
                  id="testingForCanvas"
                  style={{
                    transform: `scale(${shoulderDistance / 550})`,
                    left: (xy.x - 200) * 0.4166 - 40 + "%",
                    top: xy.y * 1.005 * (shoulderDistance * 0.0007) + "%",
                  }}
                  src={
                    medal === "GOLD"
                      ? goldM
                      : medal === "SILVER"
                      ? silverM
                      : medal === "BRONZE"
                      ? bronzeM
                      : bronzeM
                  }
                  alt="goldM"
                  className="goldM"
                />
              </div> */}{" "}
      </div>{" "}
    </>
  );
};

export default MedalPosing;
