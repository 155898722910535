/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./Intro.css";

// import Lines from "../../assets/intro/Lines.png";
// import JMLogo from "../../assets/intro/JM Logo.png";
// import SubTitle from "../../assets/intro/Substacker Title.png";
// import Boardwalk from "../../assets/intro/Boardwalk Arcade Text.png";

import submitButton from '../../assets/intro/Start Button.png';
import loadingButton from '../../assets/intro/loading-button.png';

import introBack from '../../assets/backgrounds/1.png'
// import bottomShadow from '../../assets/intro/Bun Shadow.png';


// import introvid from '../../assets/intro/introvid.mp4';
import useSound from 'use-sound';
import audio1 from "../../assets/sounds/Big Beat Blast.wav"

const Intro = ({ clicked }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [play1] = useSound(audio1)


  useEffect (() => {
    setTimeout(() => {
      clicked()
    }, 8000);
  }, []);




  // play1()

  
  return (
    <div className="intro-container ">
              <div className="frame-container">

      <img  src={introBack} alt="introBack" className="starterFrame" />
      </div>
      {/* <video className='intro-video' loop={true} muted={true} autoPlay={true} playsInline={true}>
        <source src={introvid} type="video/mp4" />
      </video> */}



    <div className='intro-content'>

      <div className='intro-button-parent'>
        
        <button className={`intro-button ${isLoading && 'wave-anim'}`}>
          <img src={isLoading ? loadingButton : submitButton} alt="alt" />
          <span className={`${isLoading && 'loader'}`}></span>
        </button>
        <h2>&nbsp;loading..</h2>
      </div>

    </div>

    {/* <div className='intro-footer'>
      <span className='intro-footer-shadow'> &nbsp;</span>
    </div> */}

    </div>
  );
};

export default Intro;
