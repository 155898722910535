import React from "react";
import Confetti from "react-confetti";

const ConfettiEffect = () => {

  return (
    <div style={{position: 'absolute', width: '100vw', height: '100vh'}} className="">

        <Confetti

        />
    </div>
  )
}

export default ConfettiEffect