import './App.css';
import Intro from './components/Intro/Intro';
import Game from './components/Game/Game';
import React, { useState } from 'react';


function App() {
  const [activePage, setActivePage] = useState('intro');
  
  return (
    <div className="App">
      {activePage === 'intro' && <Intro clicked={() => setActivePage('game')} />}
      {activePage === 'game' && <Game />}
    </div>
  );
}

export default App;
