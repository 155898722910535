/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./Game.css";
import JMLogo from "../../assets/game/JMLogo.png";

import hot from "../../assets/game/hot.png";
import cold from "../../assets/game/cold.png";

import back1 from "../../assets/backgrounds/1.png";
import back2 from "../../assets/backgrounds/2.png";
// import instruct from '../../assets/game/instruct.png'

import howtoplay from "../../assets/game/howtoplay.png";
import ReactGA from "react-ga4";

import Hot from "../Hot/Hot";
import Cold from "../Cold/Cold";

import Lines from "../../assets/intro/Lines.png";
import SubTitle from "../../assets/intro/Substacker Title.png";
import camera from "../../assets/game/camera.png";

import audio1 from "../../assets/sounds/Big Beat Blast.mp3";

import audio2 from "../../assets/sounds/Text Frame Opening.wav";

import audio3 from "../../assets/sounds/Times up.mp3";

import audio4 from "../../assets/sounds/Collect Item.mp3";
import audio5 from "../../assets/sounds/Wrong Answer.wav";

import audio6 from "../../assets/sounds/Teddy Bear.wav";
import audio7 from "../../assets/sounds/Football.wav";
import audio8 from "../../assets/sounds/Rubber Duck.wav";
import audio9 from '../../assets/sounds/Countdown.mp3'
import ConfettiEffect from "../Confetti/Confetti";

import MedalPosing from "../MedalPosing/MedalPosing";

import { FullScreen, useFullScreenHandle } from "react-full-screen";

import medalBun from "../../assets/medal_bun.png";
import medalBunHot from "../../assets/medal_bun_hot.png";
import medalBunMike from "../../assets/medal_bun_mike.png";

import InstructionsText from "../../assets/texts/InstructionsText.png";
import congratulations from "../../assets/texts/congratulations.png";

import chooseyoursub from "../../assets/texts/ChooseUrSub.png";

import wonBronze from "../../assets/texts/WonBronze.png";
import wonSilver from "../../assets/texts/WonSilver.png";
import wonGold from "../../assets/texts/WonGold.png";



const sound1 = document.getElementById("sound1");
const sound2 = document.getElementById("sound2");
const sound3 = document.getElementById("sound3");

const sound4 = document.getElementById("sound4");
const sound5 = document.getElementById("sound5");
const sound6 = document.getElementById("sound6");
const sound7 = document.getElementById("sound7");
const sound8 = document.getElementById("sound8");
const sound9 = document.getElementById("sound9");

const silence =
  "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

function isIOS() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

const Game = () => {

  const handle = useFullScreenHandle();

  // const [loading, setLoading] = useState(null);
  // const [level, setLevel] = useState(4);
  // const [gameplayStatus, setGameplayStatus] = useState(6);
  // const [second, setSeconds] = useState(30);
  // const [second2, setSeconds2] = useState(3);
  // const [hide, setHide] = useState(null);
  // const [points, setPoints] = useState([]);
  // const [wPoints, setWPoints] = useState([]);
  // DEFAULT
  const [loading, setLoading] = useState(null);
  const [level, setLevel] = useState(0 );
  const [gameplayStatus, setGameplayStatus] = useState(1);
  const [second, setSeconds] = useState(30);
  const [second2, setSeconds2] = useState(3);
  const [hide, setHide] = useState(null);
  const [points, setPoints] = useState([]);
  const [wPoints, setWPoints] = useState([]);

  if((second === 3 || second2 === 3) && gameplayStatus >= 3){
    playCustomAudio("audio9");
  }
  console.log('level: ' + level, 'status: ' + gameplayStatus )

  const [allowedCamera, setAllowedCamera] = useState(false);

  const requestPermission = () => {
    if (DeviceOrientationEvent?.requestPermission !== undefined) {
      DeviceOrientationEvent.requestPermission().then((response) => {
        if (response === "granted") {
          setAllowedCamera(true);
        } else {
          alert("Please Reload the page and ALLOW Device Orientation Control");
        }
      });
    } else {
      setAllowedCamera(true);
    }
  };


  if (!isIOS()) {
    navigator.permissions.query({ name: "camera" }).then((res) => {
      if (res.state === "granted") {
        setAllowedCamera(true);
      }
    });
  }

  if (level === 2) {
    if (!isIOS()) {
      setInterval(() => {
        navigator.permissions.query({ name: "camera" }).then((res) => {
          if (res.state === "granted") {
            setAllowedCamera(true);
          }
        });
      }, 100);
    }
  }

  if (allowedCamera === true && level === 2) {
    setGameplayStatus(3);
    setLevel(3);
  }

  if (level === 1) {
    setTimeout(() => {
      setHide("howtoplay");
    }, 7000);
    setTimeout(() => {
      playCustomAudio("audio2");
      setLevel(2);
    }, 8000);
  }

  if (gameplayStatus === 4) {
    if (second > 0) {
      if (loading === "cold") {
        setTimeout(() => {
          setSeconds(second - 1);
        }, 920);
      } else {
        setTimeout(() => {
          setSeconds(second - 1);
        }, 920);
      }
    } else {
      setGameplayStatus(null);
      sound1.src = silence;
      playCustomAudio("audio3");
      setTimeout(() => {
        setGameplayStatus(5);
      }, 1000);
    }
  }

  const nextLevel = (param) => {
    try {
      ReactGA.event({
          category: "choosen_sub",
          action: `${param}`
      });
    }catch(e){ console.log("ERR",e);}

    try {
      handle.enter();
    } catch (e) {
      console.log(e);
    }



    sound1.src = silence;
    sound1.volume = 0.2
    sound2.src = silence;
    sound3.src = silence;
    sound4.src = silence;
    sound5.src = silence;
    sound6.src = silence;
    sound7.src = silence;
    sound8.src = silence;
    sound9.src = silence;

    sound1.play();
    sound2.play();
    sound3.play();
    sound4.play();
    sound5.play();
    sound6.play();
    sound7.play();
    sound8.play();
    sound9.play();

    requestPermission();
    playCustomAudio("audio2");
    playCustomAudio("audio1");
    if (level === 0) {
      setLoading(param);
      setLevel(1);
    }

    setTimeout(() => {}, 1000);
  };

  const calculatePoints = () => {
    const wrongArray = [
      "duck",
      "football",
      "duck",
      "bear",
      "duck",
      "bear",
      "football",
      "duck",
      "football",
      "bear",
      "duck",
      "football",
      "bear",

      "duck",
      "football",
      "duck",
      "bear",
      "duck",
      "bear",
      "football",
      "bear",
      "duck",
      "football",
      "bear",
      "duck",
      "football",
    ];

    let Cpoints = points.filter((item) => item < 20).length * 10;

    // eslint-disable-next-line array-callback-return
    wPoints.map((item) => {
      if (wrongArray[item] === "duck") {
        Cpoints -= 5;
      } else if (wrongArray[item] === "football") {
        Cpoints -= 15;
      } else if (wrongArray[item] === "bear") {
        Cpoints -= 10;
      }
    });
    return Cpoints;
  };

  const medal = (points) => {
    points = calculatePoints();

    if (points <= 74) {
      return "BRONZE";
    } else if (points >= 75 && points <= 149) {
      return "SILVER";
    } else if (points >= 150) {
      return "GOLD";
    }
  };

  if(gameplayStatus === 3) {
    if(second2 > -1) {
      setTimeout(() => {
        setSeconds2(second2 -1)
      }, 1000);
    }else{
      setGameplayStatus(4)
    }
  }

  if (gameplayStatus === 5) {
    setTimeout(() => {
      setGameplayStatus(6);
    }, 5000);
  }

  function playCustomAudio(name) {
    switch (name) {
      case "audio1":
        sound1.src = audio1;
        break;

      case "audio2":
        sound2.src = audio2;
        break;

      case "audio3":
        sound3.src = audio3;
        break;

      case "audio4":
        sound4.src = audio4;
        break;

      case "audio5":
        sound5.src = audio5;
        break;

      case "audio6":
        sound6.src = audio6;
        break;

      case "audio7":
        sound7.src = audio7;
        break;

      case "audio8":
        sound8.src = audio8;
        break;
      case "audio9":
        sound9.src = audio9;
        break;
      default:
        return null;
    }
  }

  if(gameplayStatus === 6) {
    sound1.src = silence;
    sound2.src = silence;
    sound3.src = silence;
    sound4.src = silence;
    sound5.src = silence;
    sound6.src = silence;
    sound7.src = silence;
    sound8.src = silence;
    sound9.src = silence;
  
  }

  const restartGame = () => {
    setSeconds(30)
    setSeconds2(3)
    setGameplayStatus(2)
    setHide(null)
    setPoints([])
    setWPoints([])
    setLevel(0)
    setLoading(null)
  }

  return (
    <FullScreen handle={handle}>
      <div className={`game-container `}>
        <div className="frame-container">
          <img
            src={level >= 1 ? back2 : back1}
            alt="starterFrame"
            className="starterFrame"
          />

          {/* <div className='progress-bar'>
        {
          gameplayStatus === 4 && 
          progressBarParams.map((param, index) => {
            if(index > (points.length - 1)) {
              return null
            }
            return (
              <span key={index} style={{top: param, height: loading === 'hot' ? '1.8%' : '2.08%' }}>
              &nbsp;
            </span>
            )
          }
          )
        }
      </div> */}
        </div>
        {/* <img src={JMLogo} alt='JMLogo' className='JMLogo'/> */}
        <div className="game-content">
          {level === 0 ? (
            <>
              <header>
                <div className="jmlogo">
                  <img src={JMLogo} alt="alt" />
                </div>
                <div className="lines">
                  <img src={Lines} alt="alt" />
                  <span className="subtitle">
                    <img src={SubTitle} alt="alt" />
                  </span>
                  {/* <span className="boardwalk">
            <img src={Boardwalk} alt="alt" />
          </span> */}
                </div>
              </header>
              <div className="game-content-quest">
                <span className="quest-parent">
                  {/* <img src={quest} alt='quest' className='quest'/> */}
                  {/* <h5>CHOOSE YOUR SUB</h5> */}
                  <img
                    style={{ width: "70vw" }}
                    src={chooseyoursub}
                    alt="alt"
                  />
                </span>
              </div>

              <div className="hotorcold">
                <span className="hot-parent">
                  <img
                    onClick={() => nextLevel("hot")}
                    src={hot}
                    alt="hot"
                    className={`${"hot"}`}
                  />
                </span>
                <span className="cold-parent">
                  <img
                    onClick={() => nextLevel("cold")}
                    src={cold}
                    alt="cold"
                    className={`${"cold"}`}
                  />
                </span>
              </div>
            </>
          ) : level === 1 ? (
            <div className={`howtoplay ${hide === "howtoplay" && "hide"}`}>
              <span className="quest-parent">
                <span className="text-parent">
                  {/* <p>HOW TO PLAY</p>
                  <p>Allow access to device's camera</p>
                  <p>
                    Slide <img src={hand} alt="hand" className="hand" /> the bun
                    from left to right to catch ingredients
                  </p>
                  <p>Watch out for non-food items!</p>
                  <p>You have 30 seconds to catch ingredients</p>
                  <p>Best of luck!</p> */}
                  <img
                    style={{ width: "60vw" }}
                    src={InstructionsText}
                    alt="alt"
                  />
                </span>
              </span>
            </div>
          ) : level === 2 ? (
            <div>
              <div className="gameplay camera-allow-parent">
                <img src={camera} alt="camera" className="camera" />
                <h2>ALLOW ACCESS TO CAMERA</h2>
                {/* <div style={{opacity: '0'}}>
                  <Webcam />
                </div> */}

                {/* <span className={`quest-parent-1 ${gameplayStatus !== 1 && 'hide'}`}>
                <img src={quest} alt='quest' className='quest'/>
                <h5>GET READY</h5>
                </span>

                <span className={`quest-parent-2 ${gameplayStatus !== 2 && 'hide'}`}>
                <img src={howtoplay} alt='quest' className='quest'/>
                <h5>{second}</h5>
                </span>

                <span className={`quest-parent-1 ${gameplayStatus !== 3 && 'hide'}`}>
                <img src={quest} alt='quest' className='quest'/>
                <h5>CATCH</h5>
                </span>

                <span className={`quest-parent-2 ${gameplayStatus !== 4 && 'hide'}`}>
                <img src={howtoplay} alt='quest' className='quest'/>
                <h5>{second}</h5>
                </span> */}
              </div>
            </div>
          ) :
          gameplayStatus === 4 && (
            <div className="gameplay">
              <span
                className={`quest-parent-2 ${gameplayStatus !== 4 && "hide"}`}
              >
                <span className="score">
                  <h3>score: {calculatePoints()}</h3>
                </span>

                <img src={howtoplay} alt="quest" className="quest" />
                <h5 className={second <=  10 && `countdown-text`} style={{ color: second <= 10 ? "red" : "white" }}>
                  {second}
                </h5>
              </span>
            </div>
          )}
          <div className="ingredients-container">
          {
            gameplayStatus === 3 ? (
              <div className="gameplay">
              <span
                className={`quest-parent-2`}
              >
                <img src={howtoplay} alt="quest" className="quest" />
                <h5 className={second2 <=  10 && `countdown-text`} style={{ color: second2 <= 10 ? "red" : "white" }}>
                  {second2}
                </h5>
              </span>
            </div>
            ) :
          gameplayStatus === 4 ? (
              loading === "hot" ? (
                <Hot
                  setWPoints={setWPoints}
                  points={points}
                  setPoints={setPoints}
                  time={second}
                  playCustomAudio={playCustomAudio}
                />
              ) : loading === "cold" ? (
                <Cold
                  setWPoints={setWPoints}
                  points={points}
                  setPoints={setPoints}
                  time={second}
                  playCustomAudio={playCustomAudio}
                />
              ) : null
            ) : null}

            {gameplayStatus === 5 && (
              <div style={{width: '100vw', height: '100vh'}} className="congrat-container">
                {/* <video
                  style={{
                    position: "absolute",
                    height: "150vh",
                    width: "150vw",
                    top: "-30%",
                    left: "0",
                  }}
                  muted={true}
                  autoPlay={true}
                  playsInline={true}
                >
                  <source src={confetti} type="video/webm" />
                </video> */}

                <ConfettiEffect />


                <div
                  style={{
                    position: "absolute",
                    width: "100vw",
                    left: "0",
                    top: "85%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <h3 className='youhavewon' style={{ top: '-40vh', width: "70vw", position:'absolute' }}>congratulations, you won {calculatePoints()} points</h3>
                 {/* <img
                  style={{ top: '-33vh', width: "70vw", position:'absolute' }}
                  src={congratulations}
                  alt="alt"
                />
                <br />
                <br />
                <img
                  src={
                    medal(points) === "GOLD"
                      ? wonGold
                      : medal(points) === "SILVER"
                      ? wonSilver
                      : wonBronze
                  }
                  style={{ top: '-25vh', width: "60vw", position: "absolute",zIndex: '10' }}
                  alt="alt"
                /> */}
                  <img
                    style={{ width: "70%" }}
                    src={
                      loading === "hot"
                        ? medalBunHot
                        : medal(points) === "GOLD"
                        ? medalBunMike
                        : medalBun
                    }
                    alt="burger"
                    className="burger"
                  />
                </div>
              </div>
            )}

            {gameplayStatus === 6 && (
              <MedalPosing
                restartGame={restartGame}
                points={calculatePoints()}
                medal={medal(points)}
              />
            )}
          </div>
        </div>
      </div>
    </FullScreen>
  );
};

export default Game;
